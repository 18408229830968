import { Flex, Heading, List, ListItem, Stack } from "@biblioteksentralen/react";
import { useId } from "react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { DefaultContainer } from "../../ContentContainer";
import { CreateNew } from "../../editInSanity/EditInSanity";
import BookListPreview from "../../lists/BookListPreview";
import BookListPreviewLarge from "../../lists/BookListPreviewLarge";
import RecommendationCard from "../../recommendation/RecommendationCard";
import RecommendationCardLarge from "../../recommendation/RecommendationCardLarge";
import SeMerLenke from "../SeMerLenke";
import { ResolvedListSummary, ResolvedRecommendationSummary } from "@libry-content/common";

interface Props {
  listAndRecommendations?: (ResolvedListSummary | ResolvedRecommendationSummary)[];
}

function BookListBanner({ listAndRecommendations }: Props) {
  const { t } = useTranslation();
  const headingId = useId();

  if (!listAndRecommendations?.length) return null;

  if (listAndRecommendations.length === 1 && listAndRecommendations[0]) {
    return listAndRecommendations[0]._type === "list" ? (
      <DefaultContainer>
        <BookListPreviewLarge list={listAndRecommendations[0]} tagline={t("Nye anbefalinger")} />
      </DefaultContainer>
    ) : (
      <DefaultContainer>
        <RecommendationCardLarge recommendation={listAndRecommendations[0]} />
      </DefaultContainer>
    );
  }

  return (
    <DefaultContainer as="section" aria-labelledby={headingId}>
      <Stack spacing="1rem">
        <Heading as="h2" id={headingId}>
          {t("Våre anbefalinger")}
        </Heading>
        <Flex gap=".5rem" flexWrap="wrap">
          <CreateNew type="list" />
          <CreateNew type="recommendation" />
        </Flex>
        <List display="flex" gap="1rem" flexWrap="wrap">
          {listAndRecommendations.map((listOrRecommendation) => (
            <ListItem key={listOrRecommendation._id} flex="1 0 13rem">
              {listOrRecommendation._type === "list" ? (
                <BookListPreview list={listOrRecommendation} headingSize="h2" />
              ) : (
                <RecommendationCard recommendation={listOrRecommendation} headingSize="h2" />
              )}
            </ListItem>
          ))}
        </List>
        <SeMerLenke marginX=".75rem" alignSelf="flex-end" href="/lister">
          {t("Se alle anbefalinger")}
        </SeMerLenke>
      </Stack>
    </DefaultContainer>
  );
}

export default BookListBanner;
